import { defineComponent, PropType } from 'vue'
import './index.scss'
type ImageFit = 'none' | 'contain' | 'cover' | 'fill' | 'scale-down'
type NameShowType = 'none' | 'hover' | 'override' | 'down'
export default defineComponent({
  name: 'GfrImage',
  props: {
    width: {
      type: [String, Number],
      default: 'auto'
    },
    height: {
      type: [String, Number],
      default: 'auto'
    },
    fit: {
      type: String as PropType<ImageFit>,
      default: 'none'
    },
    lazy: Boolean,
    src: String,
    name: String,
    alt: String,
    previewList: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    nameShow: {
      type: String as PropType<NameShowType>,
      default: 'none'
    },
    events: {
      type: String as PropType<'none' | 'auto'>,
      default: 'auto'
    }
  },
  emits: ['load', 'error'],
  setup(props) {
    const lazyLoading = () => {
      if (!('loading' in HTMLImageElement.prototype)) {
        // TODO lazyLoad handler
      }
    }
    onMounted(() => {
      if (props.lazy) {
        lazyLoading()
      }
    })
    return () => (
      <div class={'gfr-image'}>
        <img
          class={['gfr-image-inner', `gfr-image-inner-${props.fit}`, props.lazy && 'gfr-image-lazy']}
          src={props.src}
          alt={props.alt || props.name}
          loading={props.lazy ? 'lazy' : 'eager'}
        />
        {props.name && props.nameShow !== 'none' && (
          <span class={['gfr-image-name', `gfr-image-name-${props.nameShow}`]}>{props.name}</span>
        )}
      </div>
    )
  }
})
